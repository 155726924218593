import { createForm } from "remix-forms";
import {
  useActionData,
  useSubmit,
  useNavigation,
  Form as FrameworkForm,
} from "@remix-run/react";
import {
  Select,
  Radio,
  RadioGroup,
  InputWrapper,
  Label,
  Checkbox,
  TextArea,
  Input,
  Field,
  SaveButton,
  Error,
  Errors,
} from "~/components/FormComponents";
import Tooltip from "../components/Tooltip";

const inputClassName = (errors) => {
  if (errors) {
    return "border-red-600 focus:border-red-600 focus:ring-red-600";
  }
  return undefined;
};

const defaultField = ({ Field, ...props }) => {
  const {
    errors,
    name,
    label,
    fieldType,
    radio,
    required,
    isDisabled,
    tooltip,
    isLowerCase,
  } = props;

  if (radio && fieldType !== "boolean") {
    return (
      <Field key={name} {...props}>
        {({ Label, SmartInput, Errors }) => {
          return (
            <>
              <Label className={errors ? "text-red-500" : undefined}>
                {label}
                {required && <sup style={{ color: "red" }}> *</sup>}
              </Label>
              <RadioGroup disabled={isDisabled} isLowerCase={isLowerCase}>
                <SmartInput {...props} />
              </RadioGroup>
              <div className="h-[20px]">
                <Errors />
              </div>
            </>
          );
        }}
      </Field>
    );
  }

  return (
    <Field key={name} {...props}>
      {({ Label, SmartInput, Errors }) => {
        return (
          <>
            <Label className={errors ? "text-red-500" : undefined}>
              {label}
              {required && <sup style={{ color: "red" }}> *</sup>}
              {tooltip && <Tooltip title={tooltip} />}
            </Label>
            <SmartInput
              disabled={isDisabled}
              className={inputClassName(errors)}
              {...props}
            />
            <div className="h-[20px]">
              <Errors />
            </div>
          </>
        );
      }}
    </Field>
  );
};

const BaseForm = createForm({
  component: "form",
  useNavigation,
  useSubmit,
  useActionData,
});

const Form = ({ className, method = "POST", ...props }) => {
  return (
    <BaseForm
      className={className ? `grid-layout ${className}` : "grid-layout"}
      mode="onChange"
      method={method}
      renderField={defaultField}
      buttonComponent={SaveButton}
      fieldComponent={Field}
      inputComponent={Input}
      selectComponent={Select}
      radioComponent={Radio}
      radioGroupComponent={RadioGroup}
      radioWrapperComponent={InputWrapper}
      checkboxWrapperComponent={InputWrapper}
      checkboxComponent={Checkbox}
      labelComponent={Label}
      multilineComponent={TextArea}
      globalErrorsComponent={Errors}
      errorComponent={Error}
      {...props}
    />
  );
};

export default Form;
